import React from "react";
import { Link } from "gatsby";
import { EXTERNAL_ROUTE_LINKS, ROUTE_LINKS } from "../utils/routes";
import cx from "classnames";
import MenuDropdown from "./menu-dropdown/MenuDropdown";
import CustomMenuItem from "./menu-dropdown/CustomMenuItem";
import MAnchor from "./MAnchor";

interface Props {
  currentRoute?: string;
  setShowRequestApiDocsModal: Function;
  setShowDtcApplyModal: Function;
}

const HeaderNavList: React.FC<Props> = (props: Props) => {
  const {
    currentRoute,
    setShowDtcApplyModal,
    setShowRequestApiDocsModal,
  } = props;

  const getNavColor = (route: string) => {
    if (currentRoute === route) {
      return "text-primary";
    }
  };

  return (
    <div className="hidden md:flex p-2 items-center justify-end w-full text-base">
      <Link
        to={ROUTE_LINKS.index}
        className={cx(
          "hover:text-primary pr-8 lg:pr-16 pb-2",
          getNavColor(ROUTE_LINKS.index)
        )}
      >
        Home
      </Link>

      <MenuDropdown
        menuName="Property Managers"
        toLink={ROUTE_LINKS.managerLanding}
        className={cx(
          "pr-8 lg:pr-16 pb-2",
          getNavColor(ROUTE_LINKS.managerLanding)
        )}
      >
        <CustomMenuItem curveTopBorder>
          <MAnchor url={EXTERNAL_ROUTE_LINKS.Login} ariaLabel="Login">
            Matrix Connect
          </MAnchor>
        </CustomMenuItem>
        <CustomMenuItem showBottomBorder>
          <p onClick={() => setShowRequestApiDocsModal(true)}>
            Request API Docs
          </p>
        </CustomMenuItem>
      </MenuDropdown>

      <MenuDropdown
        menuName="Renter"
        toLink={ROUTE_LINKS.renterLanding}
        className={cx(
          "pr-8 lg:pr-16 pb-2",
          getNavColor(ROUTE_LINKS.renterLanding)
        )}
      >
        <CustomMenuItem showBottomBorder curveTopBorder>
          <p onClick={() => setShowDtcApplyModal(true)}>Apply Now</p>
        </CustomMenuItem>
      </MenuDropdown>

      <MenuDropdown
        menuName="About Us"
        toLink={ROUTE_LINKS.aboutUs}
        className={cx("pr-8 lg:pr-16 pb-2", getNavColor(ROUTE_LINKS.aboutUs))}
      >
        <CustomMenuItem curveTopBorder>
          <Link
            to={ROUTE_LINKS.news}
            className={cx("hover:text-primary", getNavColor(ROUTE_LINKS.news))}
          >
            News
          </Link>
        </CustomMenuItem>
        <CustomMenuItem>
          <Link
            to={ROUTE_LINKS.careers}
            className={cx(
              "hover:text-primary",
              getNavColor(ROUTE_LINKS.careers)
            )}
          >
            Careers
          </Link>
        </CustomMenuItem>
        <CustomMenuItem showBottomBorder>
          <Link
            to={ROUTE_LINKS.contactUs}
            className={cx(
              "hover:text-primary",
              getNavColor(ROUTE_LINKS.contactUs)
            )}
          >
            Contact Us
          </Link>
        </CustomMenuItem>
      </MenuDropdown>

      <Link
        to={ROUTE_LINKS.signup}
        className={cx(
          "hover:text-primary pr-8 lg:pr-16 pb-2",
          getNavColor(ROUTE_LINKS.signup)
        )}
      >
        Signup
      </Link>
    </div>
  );
};

export default React.memo(HeaderNavList);
